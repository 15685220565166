import * as React from 'react';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormEvent, Ref, useState} from 'react';

export type TextAreaProps = {
    displayCounter?: boolean;
    disabled?: boolean;
    error?: string;
    maxLength?: number;
    name: string;
    parentCSS?: string;
    placeholder: string;
    rows?: number;
    validate?: (value) => string | null;
    value?: string | string[];
    required?: boolean;
    ref?: Ref<unknown> | null;
} & Omit<JSX.IntrinsicElements[`textarea`], 'value' | 'name' | 'placeholder'>;

export const TextAreaControl = (
    {className = ``, error, name = ``, parentCSS = ``, disabled = false, value, required, placeholder, ...other}: TextAreaProps,
    ref,
) => {
    const initialLength = typeof value === `string` ? value.length : 0;
    const [characterCount, setCharacterCount] = useState(initialLength);

    // set default options
    const options = {
        displayCounter: false,
        maxLength: 900,
        rows: 4,
        ...other,
    };

    /**
     * Template
     */
    return (
        <div className="tw-mb-3">
            <div className={`tw-mb-0 tw-relative ${parentCSS}`}>
                <textarea
                    className={`tw-block tw-rounded-sm tw-pt-2 tw-px-3 tw-w-full tw-border-solid focus:tw-ring-2 ${
                        error
                            ? 'tw-border-2 !tw-border-red-500 focus:tw-border-red-500 focus:tw-ring-red-300'
                            : 'tw-border tw-border-gray-200 focus:tw-border-gray-600 focus:tw-ring-sky-200'
                    } tw-appearance-none focus:tw-outline-none ${
                        disabled ? 'tw-bg-gray-100 tw-cursor-not-allowed tw-text-gray-400' : 'tw-bg-white tw-text-gray-900'
                    } ${className} `.trim()}
                    maxLength={options.maxLength}
                    onBlur={other.onBlur}
                    onChange={other.onChange}
                    onKeyDown={other.onKeyDown}
                    onInput={(e: FormEvent<HTMLTextAreaElement>) => setCharacterCount(e.currentTarget.value.length)}
                    placeholder={`${placeholder} ${required ? '*' : ''}`}
                    ref={ref}
                    rows={options.rows}
                    style={other.style}
                    value={value}
                    name={name}
                />
                {error && (
                    <FontAwesomeIcon
                        className="tw-absolute tw-right-3 tw-text-red-500 tw-my-auto tw-top-[50%] tw-bottom-[50%]"
                        icon={faExclamationCircle}
                        size="lg"
                    />
                )}
            </div>
            <div className={'tw-flex tw-justify-between'}>
                {error && <label className="tw-text-red-500 tw-text-sm tw-static tw-mt-1">{error}</label>}
                {!error && <div />}
                {options.displayCounter && (
                    <span className="tw-text-right">
                        <span className="tw-text-sm tw-text-gray-400">
                            {characterCount} / {options.maxLength}
                        </span>
                    </span>
                )}
            </div>
        </div>
    );
};
export const TextArea = React.forwardRef(TextAreaControl);
