import * as React from 'react';
import * as yup from 'yup';
import {useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {Alerts} from '../ui/Alerts/Alerts';
import {ContentModal} from '../ui/modals/ContentModal';
import {gaLegacyCustomEvent} from '../../client/ga/ga-legacy.functions';
import {ImpError} from '../../client/imp-error/imp-error.class';
import {Input} from '../ui/forms/Input/Input';
import {ListOperation} from './lists/lists.class';
import {ListsService} from '../../client/lists/lists.service';
import {ListType, ListTypeSelection} from './list.class';
import {ProgressOverlay} from '../../client/components/ProgressOverlay';
import {Radio} from '../ui/forms/Radio/Radio';
import {useService} from '../react/ServiceContext';
import {validateListName} from '../tools/yup-validators.functions';
import Button from '../ui/Buttons/Button';

interface CreateNewListFormValues {
    listName: string;
    listTypeSelection: ListTypeSelection;
}

interface CreateNewListModalProps {
    afterCreateNewListRes: (newListId: string, newListName: string) => void;
    listOperation: ListOperation;
    onClose: () => void;
    show: boolean;
    titleOverride?: string;
    tokenId?: string;
}

export const CreateNewListModal = ({
    afterCreateNewListRes,
    listOperation,
    onClose,
    show,
    titleOverride,
    tokenId,
}: CreateNewListModalProps) => {
    const [updateListErrMessage, setUpdateListErrMessage] = useState(``);
    const formRef = useRef(null);
    const listsService: ListsService = useService(`listsService`);
    const formValidation = yup.object().shape({
        listName: validateListName(),
    });
    const useFormReturn = useForm<CreateNewListFormValues>({
        defaultValues: {
            listName: listOperation.listName,
            listTypeSelection: `public`,
        },
        resolver: yupResolver(formValidation),
    });

    /**
     * TBD
     * @param createNewListForm
     */
    const submitCreateNewListForm = (createNewListForm: CreateNewListFormValues) => {
        let newListName = createNewListForm.listName;
        const listTypeSelection = createNewListForm.listTypeSelection;
        setUpdateListErrMessage(``);
        ProgressOverlay.activate();

        // Determine if it will be default list
        const defaultList = newListName.toLowerCase() === `my favorites` ? `yes` : `no`;
        newListName = defaultList === `yes` ? `` : newListName;

        // Determine listType
        let listType: ListType = ``;
        if (defaultList === `yes`) {
            listType = `d`;
        } else if (listTypeSelection === `private`) {
            listType = `p`;
        }

        // Create list
        listsService
            .updateList(listOperation.listId, listOperation.listAction, '', defaultList, newListName, listType, tokenId)
            .then((updateListRes) => {
                const newListId = updateListRes.ezid ? updateListRes.ezid.toString() : ``;

                // Invoke afterCreateNewListRes if provided
                if (afterCreateNewListRes) {
                    afterCreateNewListRes(newListId, newListName);
                }

                // Send analytics
                gaLegacyCustomEvent({eventAction: listOperation.listAction, eventCategory: `lists`, eventLabel: newListName});

                // Close modal
                onCloseHandler();
                ProgressOverlay.deactivate();
            })
            .catch((updateListErr: ImpError) => {
                ProgressOverlay.deactivate();
                setUpdateListErrMessage(updateListErr.message);
            });
        createNewListForm.listName = ``;
    };

    /**
     * Resets the state of the modal before closing
     */
    const onCloseHandler = () => {
        setUpdateListErrMessage(``);
        onClose();
    };

    /**
     * Template
     */
    return (
        <ContentModal
            footer={
                <div className="tw-grid lg:!tw-block tw-w-full lg:tw-w-auto">
                    <Button
                        className="tw-py-2 tw-px-4 !tw-rounded tw-transition-colors tw-ease-in !tw-h-[48px] tw-mr-0 lg:tw-mb-0 tw-order-2 lg:tw-order-1 tw-w-full lg:tw-mr-5 lg:tw-w-auto"
                        onClick={() => {
                            onClose();
                        }}
                        type="button"
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="focus:tw-shadow-[0_0_0_0.2rem_rgba(108,117,125,0.5)] !tw-rounded tw-mb-4 tw-order-1 lg:tw-mb-0 lg:tw-order-2 tw-w-full lg:tw-w-auto tw-inline-block tw-px-4 tw-text-xl tw-leading-normal tw-transition-colors tw-ease-in !tw-h-[48px]"
                        onClick={() => {
                            formRef.current?.dispatchEvent(new Event(`submit`, {bubbles: true, cancelable: true}));
                        }}
                        type="button"
                        variant="secondary"
                    >
                        {titleOverride || `Create List`}
                    </Button>
                </div>
            }
            onClose={onCloseHandler}
            show={show}
            title={titleOverride || `Create New List`}
        >
            <FormProvider {...useFormReturn}>
                <form
                    onSubmit={(event) => {
                        setUpdateListErrMessage(``);
                        useFormReturn
                            .handleSubmit(submitCreateNewListForm)(event)
                            .catch((handleSubmitErr) => {
                                setUpdateListErrMessage(handleSubmitErr.message);
                            });
                    }}
                    ref={formRef}
                >
                    <Alerts
                        message={updateListErrMessage}
                        variant="danger"
                    />
                    <div className="caption text-right">* required</div>
                    <Input
                        error={useFormReturn?.formState?.errors?.listName?.message}
                        label="List Name"
                        name="listName"
                        required
                        {...useFormReturn.register(`listName`)}
                    />
                    <div>
                        <div className="pb-2">Privacy</div>
                        <Radio
                            alias="public"
                            caption="All users on the account can see this list"
                            label="Public"
                            name="listTypeSelection"
                            value="public"
                            {...useFormReturn.register(`listTypeSelection`)}
                        />
                        <Radio
                            alias="private"
                            caption="Only you can see this list"
                            label="Private"
                            name="listTypeSelection"
                            value="private"
                            {...useFormReturn.register(`listTypeSelection`)}
                        />
                    </div>
                </form>
            </FormProvider>
        </ContentModal>
    );
};
