import styled from '@emotion/styled';
export const TableCardStyles = styled.div`
    @media only screen and (max-width: 1024px) {
        .table-cards {
            empty-cells: hide;

            thead {
                display: none;
            }

            tr {
                border: 1px solid $gray-150;
                border-radius: 3px;
                padding: 1.25rem;
                margin-bottom: 16px;
                float: left;
                width: 100%;
                display: block;
            }

            tfoot {
                tr {
                    border: 0;
                    border-bottom: 5px solid #e5e5e5;
                    background-color: #f5f5f5;
                    padding: 1rem;
                    float: none;
                }
                td {
                    border: 0 !important;
                    display: table;
                    padding: 0 0 0 45% !important;
                    line-height: 2 !important;
                    position: relative;
                    width: 100%;

                    &:first-of-type::after {
                        visibility: hidden;
                    }

                    &:empty {
                        display: none;
                    }
                }

                td[data-title] {
                    &:before {
                        content: attr(data-title);
                        font-weight: 700;
                        display: table-cell;
                        width: 45%;
                        padding-left: 16px;
                    }
                }
            }

            tbody td:not([data-title]) {
                border: 0 !important;
                display: block;
                padding: 0 !important;
                line-height: 2 !important;
                position: relative;

                &:first-of-type::after {
                    visibility: hidden;
                }
            }

            tbody td[data-title] {
                border: 0 !important;
                display: table;
                padding: 0 0 0 45% !important;
                line-height: 2 !important;
                position: relative;
                width: 100%;

                &:first-of-type::after {
                    visibility: hidden;
                }

                &:empty {
                    display: none;
                }
            }

            tbody td[data-title] {
                &:before {
                    content: attr(data-title);
                    font-weight: 700;
                    display: table-cell;
                    height: 100%;
                    left: 0;
                    margin: auto;
                    vertical-align: middle;
                    white-space: nowrap;
                    width: 45%;
                }
            }
        }
    }
`;
