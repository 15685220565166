import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {forwardRef} from 'react';

import '../../client/recaptcha/recaptcha.component.scss';

interface RecaptchaProps {
    disableRecaptcha: boolean;
}

export const Recaptcha = forwardRef<ReCAPTCHA, RecaptchaProps>(({disableRecaptcha}, ref) => {
    /**
     * Template
     */
    if (disableRecaptcha) {
        return <div className="disabled-recaptcha" />;
    }
    return (
        // @ts-ignore
        <ReCAPTCHA
            badge="inline"
            ref={ref}
            sitekey="6Lep2aoUAAAAANQkUEv5nX1MO9WBOpW4LE7Ck62C"
            size="invisible"
        />
    );
});
